import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
    state: {
        products: [],
        cardsPerPage: 9,
        totalPages: 0,
        totalProducts: 0,
        page: 1,
        cart: [],
        categories: [],
        selectedCategoryNodes: [],
        selectedCategoryNodesName: [],
        selectedCategory: 'Все',
        selectedManufacturer: '',
        selectedType: '',
        selectedSub3: '',
        selectedSub4: '',
        selectedSub5: '',
        selectedSub6: '',
        selectedSub7: '',
        selectedSub8: '',
        searchValue: '',
        textures: [],
        isCatOpened: false
    },
    getters: {
        PRODUCTS(state){
            return state.products;
        },
        SELECTED_PAGE(state){
            return state.page
        },
        SELECTED_CATEGORY_NODES(state){
            return state.selectedCategoryNodes
        },
        SELECTED_CATEGORY_NAMES(state){
            return state.selectedCategoryNodesName
        },
        SELECTED_CATEGORY(state){
            return state.selectedCategory
        },
        SELECTED_MANAFACTURER(state){
            return state.selectedManufacturer
        },
        SELECTED_TYPE(state){
            return state.selectedType
        },
        SELECTED_SUB3(state){
            return state.selectedSub3
        },
        SELECTED_SUB4(state){
            return state.selectedSub4
        },
        SELECTED_SUB5(state){
            return state.selectedSub5
        },
        SELECTED_SUB6(state){
            return state.selectedSub6
        },
        SELECTED_SUB7(state){
            return state.selectedSub7
        },
        SELECTED_SUB8(state){
            return state.selectedSub8
        },
        CART(state){
            return state.cart
        },
        CATEGORIES(state){
            return state.categories
        },
        TOTAL_PAGES(state){
            return state.totalPages
        },
        TOTAL_PRODUCTS(state){
            return state.totalProducts
        },
        TEXTURES(state){
            return state.textures
        },
        IS_CAT_OPENED(state){
            return state.isCatOpened
        }
    },
    mutations: {
        SET_CART: (state, product)=>{
            if(state.cart.length){
                let isProductExist = false;
                state.cart.map(function(item){
                    if(item.id === product.id){
                        isProductExist = true;
                        item.quantity++
                    }
                })
                if(!isProductExist){
                    state.cart.push(product)
                }
            }else{
                state.cart.push(product)
            }
        },
        SET_BOOL_CATEGORY: (state, bool) =>{
            state.isCatOpened = bool
        },
        REMOVE_FROM_CART: (state, index)=>{
            state.cart.splice(index, 1)
        },
        INCREASE: (state, index)=>{
            state.cart[index].quantity++
            
        },
        DECREASE: (state, index)=>{
            if (state.cart[index].quantity > 1){
                state.cart[index].quantity--
            }
        },
        
        SET_SELECTEDCATEGORY: (state, category)=>{
            state.selectedCategory = category
        },
        SET_MANAFACTURER: (state, manafacturer)=>{
            state.selectedManufacturer = manafacturer
        },
        SET_TYPE: (state, type)=>{
            state.selectedType = type
        },
        SET_SUB3: (state, sub)=>{
            state.selectedSub3 = sub
        },
        SET_SUB4: (state, sub)=>{
            state.selectedSub4 = sub
        },
        SET_SUB5: (state, sub)=>{
            state.selectedSub5 = sub
        },
        SET_SUB6: (state, sub)=>{
            state.selectedSub6 = sub
        },
        SET_SUB7: (state, sub)=>{
            state.selectedSub7 = sub
        },
        SET_SUB8: (state, sub)=>{
            state.selectedSub8 = sub
        },
        SET_CATEGORIES: (state, categories)=>{
            state.categories = categories
        },
        SET_PRODUCTS_TO_STATE: (state, products)=>{
            state.products = products
        },
        SET_TOTAL_PAGES: (state, value)=>{
            state.totalProducts = value
            state.totalPages = Math.ceil(value / state.cardsPerPage)
        },
        SET_PAGE: (state, value)=>{
            state.page = value
        },
        SET_TEXTURES: (state, textures)=>{
            state.textures = textures
        },
        UNDO_CATEGORIES: (state)=>{
            state.selectedCategory = 'Все'
            state.selectedCategoryNodes = []
            state.selectedCategoryNodesName = []
        },
        SET_SEARCH_VALUE: (state, value)=>{
            state.searchValue = value
        },
        UNDO_SEARCH_VALUE: (state)=>{
            state.searchValue = ''
        },
            // .includes(arr[0])
        SET_SELECTED_NODE: (state, category)=>{
            
            if (!state.selectedCategoryNodes.includes(category)) {
                // state.selectedCategoryNodes.push(arr[0])
                // state.selectedCategoryNodesName.push(arr[2])
                // console.log(arr[1]);
                // console.log(state.selectedCategoryNodes);
                if (state.selectedCategoryNodes.length !=0 && state.selectedCategoryNodes[0].parentId == category.parentId) {
                    state.selectedCategoryNodes = []
                    state.selectedCategoryNodesName = []
                    console.log(state.selectedCategoryNodesName);
                    
                }

                if (state.selectedCategoryNodes.length !=0 && category.parentId == state.selectedCategoryNodes[state.selectedCategoryNodes.length -1].parentId) {
                    state.selectedCategoryNodes[state.selectedCategoryNodes.length -1] = category
                    state.selectedCategoryNodesName[state.selectedCategoryNodes.length -1] = category.name
                }else{
                    state.selectedCategoryNodes.push(category)
                    state.selectedCategoryNodesName.push(category.name)
                }
                
            }else{
                let indexOfName = state.selectedCategoryNodesName.indexOf(category.name)
                state.selectedCategoryNodesName.splice(indexOfName, state.selectedCategoryNodesName.length)

                let index = state.selectedCategoryNodes.indexOf(category)
                state.selectedCategoryNodes.splice(index, state.selectedCategoryNodes.length)
            }
            if (category.parentId == null && state.selectedCategoryNodes.length > 1) {
                state.selectedCategoryNodes = []
                state.selectedCategoryNodesName = []
                state.selectedCategoryNodes.push(category)
                state.selectedCategoryNodesName.push(category.name)
            }
            
            if (state.selectedCategoryNodes.length == 0) {
                state.selectedCategory = 'Все'
            }else{
                state.selectedCategory = category.name
            }
        }
    },
    actions: {
        SELECT_CATEGORY_NODE({commit}, category){
            commit('SET_SELECTED_NODE', category)
        },
        SET_BOOL_CATEGORY({commit}, bool){
            commit('SET_BOOL_CATEGORY', bool)
        },
        GET_CATEGORIES({commit}){
            return axios('https://davinci-home.ru:3000/api/category/all',
            {
                method: "GET",
            }).then((categories)=>{
                commit('SET_CATEGORIES', categories.data);
            })
        },
        GET_TEXTURES({commit}){
            return axios('https://davinci-home.ru:3000/api/textures/all',
                {
                    method: "GET",
                }).then((textures)=>{
                    commit('SET_TEXTURES', textures.data.rows);
                })
        },
        GET_PRODUCTS({commit}){
            if(this.state.searchValue != ''){
                return axios({
                    url: 'https://davinci-home.ru:3000/api/goods/search',
                    method: "GET",
                    params:{
                        searchVal: this.state.searchValue,
                        page: this.state.page
                    }

                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data.rows);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }
            if(this.state.selectedCategory !=='Все'){
                
                return axios({
                    url: 'https://davinci-home.ru:3000/api/goods/sort',
                    method: "GET",
                    params:{
                        category: this.state.selectedCategoryNodesName[0],
                        sub1: this.state.selectedCategoryNodesName[1],
                        sub2: this.state.selectedCategoryNodesName[2],
                        sub3: this.state.selectedCategoryNodesName[3],
                        sub4: this.state.selectedCategoryNodesName[4],
                        sub5: this.state.selectedCategoryNodesName[5],
                        sub6: this.state.selectedCategoryNodesName[6],
                        sub7: this.state.selectedCategoryNodesName[7],
                        sub8: this.state.selectedCategoryNodesName[8],
                        page: this.state.page,
                    }
                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data.rows);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }else{
                return axios(`https://davinci-home.ru:3000/api/goods/flowers/all`,{
                    method: "GET",
                    params:{
                        page: this.state.page
                    }
                    }).then((products)=>{
                        commit('SET_PRODUCTS_TO_STATE', products.data.rows);
                        commit('SET_TOTAL_PAGES', products.data.count);
                    }).catch()
            } 
        },
        GET_PAGE_NUMBER({commit}, value){
            commit('SET_PAGE', value)
        },
        ADD_TO_CART({commit}, product){
            commit('SET_CART', product);
        },
        INCREASE_QTY({commit}, index){
            commit('INCREASE', index)
        },
        DECREASE_QTY({commit}, index){
            commit('DECREASE', index)
        },
        DELETE_FROM_CART({commit}, index){
            commit('REMOVE_FROM_CART', index)
        },
        SELECT_CATEGORY({commit}, category){
            commit('SET_SELECTEDCATEGORY', category)
        },
        SELECT_MANAFACTURER({commit}, manafacturer){
            commit('SET_MANAFACTURER', manafacturer)
        },
        SELECT_TYPE({commit}, type){
            commit('SET_TYPE', type)
        },
        SELECT_SUB3({commit}, sub){
            commit('SET_SUB3', sub)
        },
        SELECT_SUB4({commit}, sub){
            commit('SET_SUB4', sub)
        },
        SELECT_SUB5({commit}, sub){
            commit('SET_SUB5', sub)
        },
        SELECT_SUB6({commit}, sub){
            commit('SET_SUB6', sub)
        },
        SELECT_SUB7({commit}, sub){
            commit('SET_SUB7', sub)
        },
        SELECT_SUB8({commit}, sub){
            commit('SET_SUB8', sub)
        },
        RESET_CATEGORIES({commit}){
            commit('UNDO_CATEGORIES')
        },
        GET_SEARCH_VALUE({commit}, value){
            commit('SET_SEARCH_VALUE', value)
        },
        RESET_SEARCH_VALUE({commit}){
            commit('UNDO_SEARCH_VALUE')
        }
    }
})
